.footer-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #edecea;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}
.footer-logo-main{
  width: 120px;
  filter: invert(100%);padding-bottom: 25px;
}
.footer-logo{width: 75px;padding-top: 25px;}
.footer-name {
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  font-family: "League Spartan";
  color: #181818;
  text-align: center;
}

.footer-links {
  width: 100%;
  gap: 50px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
  margin-left: -40px;
  margin-right: 0;
}

.footer-link-logos {
  width: 100%;
  gap: 30px;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-left: 5px;
  margin-right: 0;
}

.footer-rights {
  margin-top: 35px;
  font-family: "League Spartan";
  font-weight: 600;
  color: #181818;
  text-align: center;
  font-size: 14px;
  line-height: 10px;
}
@media only screen and (max-width: 890px) {
  .footer-container {
    margin: 0;
    padding: 0;
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .footer-links {
    flex-wrap: wrap !important;
    margin-left: 0px;
    gap: 0px;
  }

  .footer-a {
    width: 100%;
  }

  .footer-logo {
    width: 60px;
  }
}
