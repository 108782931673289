.limpieza-container {
  padding-left: 90px;
  padding-right: 90px;
  padding-top: 200px;
  display: flex;
  flex-wrap: no-wrap;
  margin-bottom: 80px;
}
.list-text {
  color: white;
  font-family: "League Spartan";
  font-weight: 400;
  font-size: 24px;
  margin-top: 20px;
  letter-spacing: 1px;
}

ul {
  list-style-type: disc;
  margin-left: 20px;
}

.video-text {
  color: white;
  font-family: "League Spartan";
  font-weight: 600;
  font-size: 32px;
  margin-top: 50px;
  letter-spacing: 2px;
}

.video {
  border-radius: 20px;
  width: 440px;
}

.ul-list-clean {
  margin-top: 40px;
}

.limpieza-left {
  width: 50%;
}

.limpieza-right {
  width: 50%;
  display: flex;
  justify-content: right;
  align-items: start;
}

.videoplayer {
  display: flex;
  justify-content: end;
  align-items: center;
}

#text-mobile {
  display: none;
}
#text-pc {
  display: block;
}

@media only screen and (max-width: 890px) {
  .limpieza-container {
    padding: 0;
    padding-left: 30px;
    padding-right: 30px;
    margin-top: 90px;
    flex-direction: column;
  }
  .limpieza-left {
    width: 100%;
  }

  .list-text {
    font-size: 20px;
  }

  #text-mobile {
    display: block;
  }
  #text-pc {
    display: none;
  }

  .video-text {
    font-size: 23px;
  }
  .limpieza-right {
    width: 100%;
    margin-top: 40px;
  }
}
