.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #151515;
  background-repeat: no-repeat;
  padding-bottom: 40px;
  padding-top: 40px;
  padding-left: 95px;
  padding-right: 95px;
  background-size: auto;
  width: 100%;
  position: sticky;
  top: 0;
  z-index: 1000; /* Ajusta el z-index según sea necesario */
}
.scrolled {
  background-color: #151515d3;
}
.navbar-logo {
  width: 118px;
}

.navbar-left-container {
  flex: 1;
  text-align: left;
}

.navbar-center-container {
  flex: 2;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 70px;
  margin-left: -100px;
}

.navbar-right-container {
  flex: 1;
  text-align: right;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 70px;
  margin-right: -50px;
}

.navbar-link-button {
  border-radius: 35px;
  background: #edecea;
  backdrop-filter: blur(2px);
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 12px 50px;
  color: #181818;
}

.navbar-link {
  color: #edecea;
  font-family: League Spartan;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  cursor: pointer;
}

.navbar-link:hover {
  transform: scale(1.08);
}

.navbar-logo:hover {
  transform: scale(1.08);
}

.logo-mini-menu {
  display: none;
}
.mobile-menu.open {
  display: none;
}
.mobile-menu {
  display: none;
}

.instagram_logo {
  width: 36px;
  margin: 0;
  padding: 0;
  display: flex;
}

.burger-menu-button {
  display: none;
}

.instagram_logo:hover {
  transform: scale(1.2);
}

@media (max-width: 1240px) {
  .mobile-menu {
    display: none;
  }
  .navbar-center-container {
    display: none;
  }

  .navbar-right-container {
    display: none;
  }
  .mobile-menu.open {
    display: block;
  }
  .logo-mini-menu {
    display: block;
  }
  .logo-mini-menuopen {
    display: none;
  }

  .navbar-logo {
    width: 101px;
  }
  .instagram_logo {
    display: none;
  }
  .instagram_logo_2 {
    display: flex;
    justify-content: end;
  }
  .insta-container {
    display: flex;
    position: absolute;
    right: 20px;
    z-index: 9999;
    justify-content: end;
    margin-top: -40px;
  }
  .navbar-navlink {
    text-align: left;
  }
  #web {
    display: none;
  }
  .navbar-middle {
    display: none;
  }
  .contact-button {
    display: none;
  }
  .burger-menu-button {
    display: flex;
    background-color: transparent;
    border: none;
    width: 40px;
  }
  .burger-menu {
    position: absolute;
    width: 100%;
    right: 10px;
    top: 45px;
  }

  .burger-img {
    width: 45px;
  }

  .burger-container {
    display: flex;
    padding-top: 25px;
    padding-bottom: 25px;
    padding-left: 25px;
    padding-right: 25px;
    border-radius: 28px;
    flex-direction: column;
    gap: 20px;
    background-color: #151515ed;
    width: 90%;
    color: white !important;
    position: absolute;
    right: 0;
    top: 50px;
    margin-left: 12px;
    margin-right: 12px;
    z-index: 9999;
    font-family: "League Spartan";
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 100%;
    border: 4px solid #f58220;
  }
  .scroll-back {
    border-radius: 0;
  }
  .navbar-container {
    padding: 40px;
  }
}
