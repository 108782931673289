.home-container {
  background-repeat: no-repeat;
  padding-left: 90px;

  background-position: right -200px top -200px;

  background-image: url("../img/fondo-home.png");
  background-size: 80%;
  display: flex;
  justify-content: start;
  align-items: start;
  flex-wrap: wrap;
  padding-top: 90px;
  padding-right: 90px;
}

.home-title {
  color: #fff;
  font-family: Bebas Neue;
  font-size: 120px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 6px;
  padding-top: 0px;
  padding-bottom: 0px;
}

.home-left-container {
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top left;
}

.home-text {
  color: #fff;
  font-family: League Spartan;
  font-size: 38px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 2px;
  margin-bottom: -10px;
}

.home-text-container {
  display: flex;
  align-items: start;
  justify-content: start;
  gap: 2px;
}

.box-text-container {
  border-radius: 5px;
  background: #edecea;
  padding: 4px 18px;
  color: #151515;
  font-family: League Spartan;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 1px;
  margin-top: 0px;
  display: flex;
  align-items: start;
  gap: 3px;
}

.box-img {
  margin-top: 3px;
}

.slider-container {
  overflow: hidden;
}

.text-animation {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 82%;
  border-radius: 20px;
  background: #fff;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);

  color: #151515;
  text-align: center;
  font-family: Bebas Neue;
  font-size: 100px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: 5px;
  padding: 10px 0px;

  gap: 10px;
  flex-shrink: 0;
}

.home-fake-text {
  color: #fff;
  text-align: center;
  font-family: League Spartan;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1px;
  padding-left: 25px;
}

.fake-search-bar {
  margin-top: 35px;
  border-radius: 100px;
  display: flex;
  align-items: center;
  border: 3px solid #edecea;
  justify-content: space-between;
  width: 66%;
  height: 52px;
}

.home-fake-icon {
  border-radius: 100px;
  background: #edecea;
  color: #181818;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  padding: 0;
  cursor: pointer;
  width: 26%;
  height: 100%;
  border: 2px solid #151515;
  transition: transform 0.3s ease-in-out;
}

.home-fake-icon:hover {
  transform: translateX(-20px);
  background: #f58220;
}

@media only screen and (max-width: 890px) {
  .home-container {
    padding: 30px;
    padding-top: 60px;
    padding-left: 30px;
  }

  .home-title {
    font-size: 75px;
    line-height: 70px;
  }

  .home-text {
    font-size: 34px;
  }
  .box-text {
    font-size: 17px;
    padding: 2px 1px;
  }
  .home-text-container {
    margin-bottom: 35px;
  }
  .text-animation {
    font-size: 40px;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 30px;
    padding: 25px;
  }
  .fake-search-bar {
    width: 100%;
    height: 70px;
    margin-top: 15px;
  }
  .home-fake-text {
    font-size: 19px;
    text-align: left;
    width: 75%;
    padding-right: 30px;
  }
}
